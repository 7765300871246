import { string } from "yup";
import {
  ERROR_USER,
  CLEAR_ERROR_USER,
  HIDE_MESSAGE,
  LOGOUT,
  GET_USER_INFO
} from "../type";

interface Info {
  employee: {};
  errorUser: string;
  message: string;
  user: User;
};

const initialstate: Info = {
  employee: {},
  errorUser: "",
  message: "",
  user: {
    id_role: 0,
    id_user: 0,
    firstname: '',
    lastname: '',
    amount: 0,
    avatar: null,
    email: '',
    is_active: 0,
    name_role: '',
    phone:''
  }

};

export default (state = initialstate, action: any) => {
  switch (action.type) {
    case GET_USER_INFO:
      return { ...state, user: action.payload };
    case ERROR_USER:
      return { ...state, errorUser: action.payload };
    case CLEAR_ERROR_USER:
      return { ...state, errorUser: "" };
    case HIDE_MESSAGE:
      return { ...state, message: "" };
    case LOGOUT:
      return { ...state, employee: {}, errorUser: "", message: "" };
    default:
      return state;
  }
};