import React, { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import { useDispatch } from "react-redux";
import { changeLayoutMode } from "./store/actions/layout.action";
import Routes from "./routes";
import { layoutModeTypes } from "./components/constants/layout";
import './assets/scss/themes.scss';
import 'remixicon/fonts/remixicon.css';
import 'boxicons/css/boxicons.min.css';
import 'line-awesome/dist/font-awesome-line-awesome/css/all.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  document.documentElement.setAttribute("data-layout", "vertical");
  document.documentElement.setAttribute("data-sidebar-size", "lg");
  localStorage.setItem("I18N_LANGUAGE", "en");
  const dispatch = useDispatch();

  // detect the theme to device
  useEffect(() => {
    if (window.matchMedia) {
      const colorsScheme = window.matchMedia('(prefers-color-scheme: dark)');
      if (colorsScheme.matches) {
        dispatch(changeLayoutMode(layoutModeTypes.DARKMODE) as any);
        document.documentElement.setAttribute('data-sidebar', layoutModeTypes.DARKMODE);
      } else {
        dispatch(changeLayoutMode(layoutModeTypes.LIGHTMODE) as any);
        document.documentElement.setAttribute('data-sidebar', layoutModeTypes.LIGHTMODE);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Routes />
      <ToastContainer
        closeButton={true}
        position="top-center"
        theme="colored"
      />
    </React.Fragment>
  );
}

export default App
