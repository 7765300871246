import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { store, persistor } from './store/store';
import App from './App';
import ErrorBoundary from './pages/errors/ErrorBundary';
import reportWebVitals from './reportWebVitals';

// axios.defaults.baseURL = `${process.env.REACT_APP_API}`;
axios.defaults.headers.common.Authorization =`Bearer ${localStorage.getItem("token")}`;
axios.defaults.headers.post["Content-Type"] = "application/x-form-urlencoded";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";


const root = ReactDOM.createRoot(
  document.getElementById('ukuaji') as HTMLElement
);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <MantineProvider>
        <NotificationsProvider position="bottom-right" limit={10} transitionDuration={250} >
          <Provider store={store} >
            <PersistGate persistor={persistor}>
              <HashRouter>
                <App />
              </HashRouter>
            </PersistGate>
          </Provider>
        </NotificationsProvider>
      </MantineProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

reportWebVitals();
