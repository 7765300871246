import { CHANGE_MODE } from "../type";
import { layoutModeTypes } from "../../components/constants/layout";

interface LayoutState {
  layoutModeType: string;
};

const initialState: LayoutState = {
  layoutModeType: layoutModeTypes.LIGHTMODE
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_MODE:
      return {
        ...state,
        layoutModeType: action.payload
      };
    default:
      return state;
  }
};
