import { AnyAction} from "redux";
import { ThunkDispatch } from "redux-thunk";
import { CHANGE_MODE } from "../type";

type Dispatch = ThunkDispatch<
Record<string, unknown>,
Record<string, unknown>,
AnyAction
>;


function changeHTMLAttribute(attribute:string, value: string | any) {
  if (document.documentElement) document.documentElement.setAttribute(attribute, value);
  return true
}

export const changeLayoutMode = (mode: string | any) => async (dispatch: Dispatch) => {
  dispatch({
    type: CHANGE_MODE,
    payload: mode
  })
  try {
    changeHTMLAttribute("data-layout-mode", mode);
    changeHTMLAttribute("data-sidebar", mode);
  } catch (error: any) {
    console.error(error)
  }
};