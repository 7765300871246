import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoaderUkuaji from "../components/common/LoaderUkuaji";

// protect route
import PrivateRoute from "./PrivateRoute";

// public page
const SignIn = lazy(() => import("../pages/auth/SignIn"));
const Layout = lazy(()=> import("../layout"));
const ForgetPassword = lazy(() => import("../pages/auth/ForgetPassword"));
const FourByFour = lazy(() => import("../pages/errors/FourByFour"));


// private routes
const Access = lazy(() => import("../pages/users/access/Access"));
const DashBoard = lazy(() => import("../pages/dashboard/index"));
const GestionUsers = lazy(() => import("../pages/users/userManage/GestionUsers"));
const SupplierList = lazy(() => import("../pages/suppliers/SupplierList"));
const CreateSupplier = lazy(() => import("../pages/suppliers/CreateSupplier"));
const DetailSupplier = lazy(() => import("../pages/suppliers/DetailSupplier"));
const Marketer = lazy(() => import("../pages/marketers/Marketer"));
const Account = lazy(() => import("../pages/setting/Account"));
const Setting = lazy(() => import("../pages/setting"));
const Orders = lazy(() => import("../pages/orders"));
// import Maintenance from "../pages/Maintenance";
const Category = lazy(() =>import("../pages/suppliers/Category"));
const Label = lazy(() => import("../pages/suppliers/Label"));
const SupplierWithdraw = lazy(()=> import("../pages/withdraw/SupplierWithdraw"));
const MarketerWithdraw = lazy(()=> import("../pages/withdraw/MarketerWithdraw"));
const OrderDetail = lazy(() => import("../pages/orders/OrderDetail"));
const Website = lazy(()=> import("../pages/website"));
const Chats = lazy(() => import("../pages/chat"));
const DetailMarketer = lazy(()=> import("../pages/marketers/DetailMarketer"));
const DetailMarketerWithdraw = lazy(() => import('../pages/withdraw/DetailMarketerWithdraw'));
const DetailSupplierWithdraw = lazy(() => import("../pages/withdraw/DetailSupplierWithdraw"));
const MailBox = lazy(() => import("../pages/mailbox"));
const DetailMail = lazy(() => import("../pages/mailbox/DetailMail"));
const TransactionLog = lazy(() => import("../pages/orders/TransactionLog"));
const UkColorPage = lazy(() => import("../pages/colors"));

// landing
const HomeLanding = lazy(() => import("../pages/website/landing/Home"));
const PartenerLanding = lazy(() => import("../pages/website/landing/partener"));
const AddPartener = lazy(() => import("../pages/website/landing/PartenerImg"));
const EditPartener = lazy(() => import("../pages/website/landing/PartenerImg"));
const Feature = lazy(() => import("../pages/website/landing/Feature"));
const Mission = lazy(() => import("../pages/website/landing/Mission"));
const FaqTitle = lazy(() => import("../pages/website/landing/FaqTitle"));
const FaqGeneral = lazy(() => import("../pages/website/landing/FaqGeneral"));
const FaqPrivacy = lazy(() => import("../pages/website/landing/FaqPrivacy"));
const FaqGeneralLanding = lazy(() => import("../pages/website/landing/faq-general"));
const FaqPrivacyLanding = lazy(() => import("../pages/website/landing/faq-privacy"));
const MissionTitle = lazy(() => import("../pages/website/landing/MissionTitle"));
const LandingMission = lazy(() => import("../pages/website/landing/mission/index"));
const InfoApp = lazy(() => import("../pages/website/landing/InfoApp"));


const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        {/** the public routes  */}
        <Route path="login" element={
          <Suspense fallback={<LoaderUkuaji />}>
            <SignIn />
          </Suspense>
        } />
        <Route path="forgot-password" element={
          <Suspense fallback={<LoaderUkuaji />}>
            <ForgetPassword />
          </Suspense>
        } />
        {/** the private routes */}
        <Route element={<PrivateRoute />}>
          <Route element={
            <Suspense fallback={<LoaderUkuaji />}>
              <Layout />
            </Suspense>
          }
          >
            <Route path="/" element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<Suspense fallback={<LoaderUkuaji />}><DashBoard /></Suspense>} />
            <Route path="manage" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <GestionUsers />
              </Suspense>} />
            <Route path="access-right" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Access />
              </Suspense>}
            />
            <Route path="orders" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Orders />
              </Suspense>} />
            <Route path="orders/:uuid" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <OrderDetail />
              </Suspense>}
            />
            <Route path='transaction-log' element={
              <Suspense fallback={<LoaderUkuaji />}>
                <TransactionLog />
              </Suspense>}
            />
            <Route path="suppliers" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <SupplierWithdraw />
              </Suspense>}
            />
            <Route path="suppliers/:id" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <DetailSupplierWithdraw />
              </Suspense>}
            />
            <Route path="categories" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Category />
              </Suspense>} />
            <Route path="labels" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Label />
              </Suspense>}
            />
            <Route path="marketer" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <MarketerWithdraw />
              </Suspense>}
            />
            <Route path='marketer/:id' element={
              <Suspense fallback={<LoaderUkuaji />}>
                <DetailMarketerWithdraw />
              </Suspense>}
            />
            <Route path="list" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <SupplierList />
              </Suspense>}
            />
            <Route path="list/:supplierUuid" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <DetailSupplier />
              </Suspense>} />
            <Route path="creer" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <CreateSupplier />
              </Suspense>}
            />
            <Route path="marketers" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Marketer />
              </Suspense>}
            />
            <Route path="marketers/:uuid" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <DetailMarketer />
              </Suspense>}
            />
            <Route path="uk_color" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <UkColorPage />
              </Suspense>}
            />
            <Route path="mailbox" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <MailBox />
              </Suspense>} />
            <Route path="mailbox/:id" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <DetailMail />
              </Suspense>} />
            <Route path="account" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Account />
              </Suspense>} />
            <Route path="settings" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Setting /></Suspense>} />
            <Route path="landing-page" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Website /></Suspense>}>
              <Route index element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <HomeLanding />
                </Suspense>
              } />
              <Route path="partener" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <PartenerLanding />
                </Suspense>
              } />
              <Route path="partener/add" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <AddPartener />
                </Suspense>
              } />
              <Route path="partener/edit/:id" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <EditPartener />
                </Suspense>
              } />
              <Route path="feature" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <Feature />
                </Suspense>
              } />
              <Route path="mission" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <LandingMission />
                </Suspense>
              } />
              <Route path="mission/add" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <Mission />
                </Suspense>
              } />
              <Route path="mission/edit/:id" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <Mission />
                </Suspense>
              } />
              <Route path="faq-title" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <FaqTitle />
                </Suspense>
              } />
              <Route path="faq-general" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <FaqGeneralLanding />
                </Suspense>
              } />
              <Route path="faq-general/add" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <FaqGeneral />
                </Suspense>
              } />
              <Route path="faq-general/edit/:id" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <FaqGeneral />
                </Suspense>
              } />
              <Route path="faq-privacy" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <FaqPrivacyLanding />
                </Suspense>
              } />
              <Route path="faq-privacy/add" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <FaqPrivacy />
                </Suspense>
              } />
              <Route path="faq-privacy/edit/:id" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <FaqPrivacy />
                </Suspense>
              } />
              <Route path="mission-title" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <MissionTitle />
                </Suspense>
              } />
              <Route path="info-app" element={
                <Suspense fallback={<LoaderUkuaji />}>
                  <InfoApp />
                </Suspense>
              } />
            </Route>
            <Route path="discussions" element={
              <Suspense fallback={<LoaderUkuaji />}>
                <Chats />
              </Suspense>}
            />
          </Route>
        </Route>
        <Route path='*' element={
          <Suspense fallback={<LoaderUkuaji />}>
            <FourByFour />
          </Suspense>
        } />
      </Routes>
    </React.Fragment>
  );
};

export default Index;