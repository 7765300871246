export const AUTH = "AUTH";
export const ERROR_AUTH = "ERROR_AUTH";
export const GET_MENU = "GET_MENU";
export const REGISTER_USER = "REGISTER_USER";
export const CLEAR_ERROR_AUTH = "CLEAR_ERROR_AUTH";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const GET_AVATAR = "GET_AVATAR";
export const LOAD = "LOAD";
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";
export const GET_SOCIAL_LINK = "GET_SOCIAL_lINK";
export const ADD_SOCIAL_LINK = "ADD_SOCIAL_LINK";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const UPDATE_SOCIAL_LINK = "UPDATE_SOCIAL_LINK";
export const ERROR_USER = "ERROR_LINK";
export const CLEAR_ERROR_USER = "CLEAR_ERROR_LINK";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_USER_INFO = "GET_USER_INFO";
export const lOADING = "lOADING";
export const ERROR_APP = "ERROR_APP";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const GET_CONFIG = "GET_CONFIG";
export const CHANGE_MODE = "CHANGE_MODE";
export const GET_STAT = 'GET_STAT';
export const GET_NOTIFICATION = "GET_NOTICATION";