import { Loader, Center } from '@mantine/core';

const LoaderUkuaji: React.FC = () => (
  <Center mt="xl" style={{
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  }}>
    <Loader size="lg" mt="lg" color="blue" />
  </Center>
);

export default LoaderUkuaji;
